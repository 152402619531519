;(function (w,d){

  const resultsContainer = document.querySelector('.formResults')
  const flashMsgContainer = document.getElementById('msgFlashContainer')

  if (resultsContainer) {
    resultsContainer.addEventListener('click', () => {
      resultsContainer.innerHTML = ''
    })
  }

  if (flashMsgContainer) {

    window.history.pushState({}, '', window.location.href.split('?')[0])

    setTimeout(() => {
      flashMsgContainer.classList.add('fade')
    }, 7000)

  }

})(window, document);
